import { ComponentType } from 'react';

import { useSelfKarmaQuery } from '@query';

import { MEMBERSHIP_TIER_ID } from '@api';

import { LaunchpadUserInfo } from '@shared/api/launchpad';
import ProfileMenuDialogKyc from '@shared/common/components/Header/ProfileMenuDialogKyc';
import { nextProjectUrl } from '@shared/constants/url';
import { formatTokenReward } from '@shared/helpers/format';

import * as Dialog from '@radix-ui/react-dialog';
import { Karma, Membership } from '@ui/uikit/components/icons/mono';
import { CrossLg } from '@ui/uikit/components/icons/mono/CrossLg';
import { Sqr as MonoSqr } from '@ui/uikit/components/icons/mono/Sqr';
import { cn, formatAmount } from '@ui/uikit/utils';
import { Sqr } from '@ui-uikit/lib/components/icons/poly';

import { useNavLayout } from './Header';
import NavLayoutLink from './NavLayoutLink';
import { NavLayoutLinkBase, NavLayoutUser } from './types';
import UserAvatar from './UserAvatar';
import { getMembershipClassNames, getMembershipName } from './utils';

const profileMenuNav: {
  id: string;
  className?: string;
  items: (NavLayoutLinkBase & { hidden?: (data: { user: NavLayoutUser }) => boolean })[];
  hidden?: (data: { user: NavLayoutUser }) => boolean;
}[] = [
  {
    id: 'public',
    items: [
      {
        id: 'karma-and-rewards',
        label: 'Karma & Rewards',
        href: nextProjectUrl.store('/profile/karma'),
      },
      {
        id: 'magic-id',
        label: 'Magic ID',
        href: nextProjectUrl.store('/profile/settings'),
      },
      {
        id: 'magic-membership',
        label: 'Magic Membership',
        href: nextProjectUrl.store('/profile/membership/dashboard'),
      },
      {
        id: 'invite-friends',
        label: 'Invite Friends',
        href: nextProjectUrl.store('/refer-a-friend'),
      },
    ],
  },
  {
    id: 'developer',
    hidden: ({ user }) => !user.data.attributes.creatorId,
    items: [
      {
        id: 'settings',
        label: 'Developer Settings',
        href: nextProjectUrl.store('/profile/developer-info'),
      },
      {
        id: 'my-projects',
        label: 'My Projects',
        href: nextProjectUrl.store('/profile/developer-dapps'),
      },
      // TODO: add href
      // {
      //   id: 'ad-promotions',
      //   label: 'Ad Promotions',
      // },
      {
        id: 'hot-offers-campaigns',
        label: 'Hot Offers Campaigns',
        href: nextProjectUrl.store('/profile/developer-campaign'),
      },
      {
        id: 'score-widget',
        label: 'Score Widget',
        href: nextProjectUrl.store('/profile/developer-info/widget'),
      },
    ],
  },
  {
    id: 'general',
    className: 'mt-auto',
    items: [
      {
        id: 'settings-and-privacy',
        label: 'Settings & Privacy',
        href: nextProjectUrl.store('/profile/settings'),
      },
      {
        // NOTE: logic inside of component
        id: 'support',
        label: 'Support',
      },
      {
        id: 'docs',
        label: 'Docs',
        external: true,
        href: 'https://docs.magic.store',
      },
      {
        id: 'become-developer',
        href: nextProjectUrl.store('/profile/become-creator'),
        label: 'Become Developer',
        hidden: ({ user }) => !!user.data.attributes.creatorId,
      },
    ],
  },
];

export interface ProfileMenuDialogProps extends Dialog.DialogProps {
  onLogout?: () => void;
  onSupport?: () => void;
  user: NavLayoutUser;
  launchpadUser?: LaunchpadUserInfo;
}

export const ProfileMenuDialog = ({
  onLogout,
  onSupport,
  user,
  launchpadUser,
  onOpenChange,
  ...props
}: ProfileMenuDialogProps) => {
  const { showKyc } = useNavLayout();
  const selfKarmaQuery = useSelfKarmaQuery(
    {
      searchParams: { period: 'daily' },
    },
    { enabled: Boolean(user) },
  );

  const { isPending: isSelfKarmaLoading, data: selfKarma } = selfKarmaQuery;

  const { totalSqrp, totalReward } = selfKarma ?? {};

  // const formattedDailyReward = formatTokenReward(dailyReward);
  const formattedTotalSqrp = isSelfKarmaLoading ? '0.00' : formatTokenReward(totalSqrp);
  const formattedTotalReward = formatTokenReward(totalReward);
  const formattedTotalRewardWithCurrency = isSelfKarmaLoading
    ? '0.00'
    : `${formattedTotalReward} SQR`;

  const membershipClassNames = getMembershipClassNames(user.data.membershipTier.attributes.key);
  const isMaxTier = user.data.membershipTier.attributes.key === MEMBERSHIP_TIER_ID.PREMIUM_PLUS;

  const stats: {
    id: string;
    label: string;
    content: string;
    Icon: ComponentType<{ className?: string }>;
    iconClassName?: string;
    className?: string;
    action?: {
      label: string;
      href: string;
      external?: boolean;
    };
  }[] = [
    // {
    //   id: 'est-daily-reward',
    //   label: 'Estimated Daily Reward',
    //   content: isSelfKarmaLoading ? '0.00' : formattedDailyReward,
    //   Icon: Sqr,
    // },
    {
      id: 'magic-id-balance',
      label: 'Magic ID Balance',
      className: 'col-span-full',
      content: formattedTotalRewardWithCurrency,
      Icon: Sqr,
    },
    {
      id: 'sqrp-balance',
      label: 'SQRp Balance',
      content: formattedTotalSqrp,
      Icon: MonoSqr,
    },
    {
      id: 'karma-balance',
      label: 'Karma Balance',
      content: formatAmount(user.data.attributes.karma),
      Icon: Karma,
      iconClassName: 'text-base-text-pink',
    },
    {
      id: 'membership',
      label: 'Membership Plan',
      content: getMembershipName(user.data.membershipTier.attributes.key),
      className: 'col-span-full',
      Icon: Membership,
      iconClassName: membershipClassNames.text,
      action: !isMaxTier
        ? {
            label: 'Upgrade',
            href: nextProjectUrl.store('/profile/membership/plans'),
            external: true,
          }
        : undefined,
    },
  ];

  const closeDialog = () => {
    onOpenChange?.(false);
  };

  const handleLogoutButtonClick = () => {
    onLogout?.();
    closeDialog();
  };

  const navItems = user.data.attributes.developerId
    ? profileMenuNav
    : profileMenuNav.filter((group) => group.id !== 'developer');

  const navItemClassName =
    'msq-sub-menu-link msq-sub-menu-link-mobile text-caption-sm-strong-m xl-msq:text-caption-sm-strong-d';

  const navGroupClassName = 'p-2 flex flex-col gap-1 xl-msq:p-4';

  return (
    <Dialog.Root onOpenChange={onOpenChange} {...props}>
      <Dialog.Portal>
        <Dialog.Overlay
          forceMount
          className="msq-dialog msq-dialog-mobile-menu-m xl-msq:msq-dialog-mobile-menu-d msq-dialog-animate"
        >
          <Dialog.Content
            forceMount
            aria-describedby={undefined}
            className={cn(
              // to hide divider when they on rounded corners of the panel
              'overflow-hidden',
              'z-20 msq-dialog-content sm-msq:msq-dialog-content-sm sm-msq:msq-dialog-content-right xl-msq:max-w-[400px]',
            )}
          >
            <div className="msq-dialog-header">
              <Dialog.Title className="msq-dialog-title ">Profile</Dialog.Title>

              <Dialog.Close asChild>
                <button className="msq-btn msq-btn-ghost msq-btn-icon-md msq-dialog-icon-button">
                  <CrossLg className="msq-btn msq-btn-icon-child" />
                </button>
              </Dialog.Close>
            </div>

            <div className="msq-dialog-body flex flex-col p-0">
              <div className="p-4 pb-3 xl-msq:px-6 xl-msq:pt-5 xl-msq:pb-1.5">
                <div className="flex items-center gap-2 xl-msq:gap-2.5 min-h-10 mb-4 xl-msq:mb-5">
                  <UserAvatar className="msq-avatar-md" user={user.data} />

                  <div className="flex flex-col gap-0.5 xl-msq:gap-0 justify-center overflow-hidden">
                    <span className="text-caption-sm-strong-a truncate">
                      {user.data.attributes.displayedName || 'Noname'}
                    </span>

                    <span className="text-caption-xs-a text-base-text-tertiary truncate">
                      {user.data.attributes.name || 'Noname'}
                    </span>
                  </div>
                </div>

                {showKyc && (
                  <ProfileMenuDialogKyc
                    className="mb-5 xl-msq:mb-[1.625rem]"
                    launchpadUser={launchpadUser}
                    onOpenChange={onOpenChange}
                  />
                )}

                <ul className="grid grid-cols-2 gap-1 xl-msq:gap-1.5">
                  {stats.map(({ action, content, className, id, label, Icon, iconClassName }) => {
                    {
                      /* TODO: remove text-[10px] and make text-caption-xs-m works  at span with {label}*/
                    }

                    return (
                      <li
                        key={id}
                        className={cn(
                          'rounded-lg flex gap-2 items-center px-3 py-2 bg-base-bg-secondary',
                          className,
                        )}
                      >
                        <div
                          className={cn(
                            'flex gap-0.5 xl-msq:gap-1 pt-0.5 flex-col flex-1 overflow-hidden',
                          )}
                        >
                          <span className="text-caption-xs-m xl-msq:text-caption-xs-d text-base-text-tertiary truncate">
                            {label}
                          </span>
                          <span className="flex gap-1.5 min-h-6 items-center text-caption-sm-strong-m xl-msq:text-caption-sm-strong-d flex-1">
                            <span className="text-base-text-tertiary flex-shrink-0">
                              <Icon
                                className={cn('size-[0.875rem] xl-msq:size-4', iconClassName)}
                              />
                            </span>
                            <span className="truncate">{content}</span>
                          </span>
                        </div>

                        {action && (
                          <NavLayoutLink
                            onClick={closeDialog}
                            // TODO: come up with better solution
                            href={action.href}
                            external={action.external}
                            className="msq-btn msq-btn-tertiary msq-btn-sm min-w-[7.5rem] mr-1 xl-msq:mr-2 xl-msq:min-w-[7.25rem]"
                          >
                            {action.label}
                          </NavLayoutLink>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>

              {/* shadow is used to make it pixel perfect because border is taking space */}
              <nav className="flex-1 flex flex-col [&>*:not(:first-child)]:shadow-inset-border-top [&>*:not(:first-child)]:shadow-base-border-light">
                {navItems.map((navGroup) => {
                  const isNavGroupHidden = navGroup.hidden && navGroup.hidden({ user });

                  if (isNavGroupHidden) {
                    return null;
                  }

                  return (
                    <div className={cn(navGroupClassName, navGroup.className)} key={navGroup.id}>
                      {navGroup.items.map((item) => {
                        const isItemHidden = item.hidden && item.hidden({ user });

                        if (isItemHidden) {
                          return null;
                        }

                        // TODO: make without condition
                        if (item.id === 'support') {
                          return (
                            <button
                              onClick={() => {
                                closeDialog();
                                onSupport?.();
                              }}
                              key={item.id}
                              className={navItemClassName}
                            >
                              {item.label}
                            </button>
                          );
                        }

                        return (
                          <NavLayoutLink
                            onClick={closeDialog}
                            key={item.id}
                            // TODO: come up with better solution
                            href={item.href}
                            external={item.external}
                            className={navItemClassName}
                          >
                            {item.label}
                          </NavLayoutLink>
                        );
                      })}
                    </div>
                  );
                })}

                <div className={navGroupClassName}>
                  <button onClick={handleLogoutButtonClick} className={navItemClassName}>
                    Logout
                  </button>
                </div>
              </nav>
            </div>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
