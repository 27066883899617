import React from 'react';

import { useModalController } from '@uikit/components/Modal';

import { KYC_STATUS, KycStatus, LaunchpadUserInfo } from '@shared/api/launchpad';
import KycRequiredModal from '@shared/common/components/modals/KycRequiredModal';
import { getKycStatusFlags } from '@shared/helpers/launchpad';

import { cn } from '@ui/uikit/utils';
import { Loading } from '@ui-uikit/lib/components/icons/mono/Loading';
import { Attention } from '@ui-uikit/lib/components/icons/poly';

export const kycStatusLabels: Record<KycStatus, string> = {
  [KYC_STATUS.INIT]: 'KYC Needed',
  [KYC_STATUS.PENDING]: 'KYC in Progress',
  [KYC_STATUS.COMPLETED]: 'KYC Verified',
  [KYC_STATUS.DELETED]: 'KYC Rejected',
  [KYC_STATUS.REJECTED_RETRY]: 'KYC Rejected',
  [KYC_STATUS.REJECTED_FINAL]: 'KYC Rejected',
};

const getKycContainerClassName = ({
  isError,
  isNeedVerification,
  isPending,
}: ReturnType<typeof getKycStatusFlags>) => {
  // NOTE: order matters
  if (isPending) {
    return `bg-base-bg-secondary`;
  }

  if (isError) {
    return `bg-base-bg-secondary-red`;
  }

  if (isNeedVerification) {
    return `bg-base-bg-secondary-orange`;
  }

  return '';
};

type KYCProps = {
  className?: string;
  launchpadUser?: LaunchpadUserInfo;
  onOpenChange?: (value: boolean) => void;
};

const ProfileMenuDialogKyc = ({ className, launchpadUser, onOpenChange }: KYCProps) => {
  const kycRequiredModal = useModalController();

  const closeProfileModal = () => {
    onOpenChange?.(false);
  };

  const status = launchpadUser?.attributes?.kycStatus;
  const kycLabel = status ? kycStatusLabels[status] : kycStatusLabels.INIT;

  const kycStatusFlats = getKycStatusFlags(status);
  const { hasVerifiedKyc, isError, isPending } = kycStatusFlats;

  const onClick = () => {
    kycRequiredModal.open();
  };

  if (hasVerifiedKyc) {
    return null;
  }

  return (
    <>
      <div
        className={cn(
          'flex items-center p-3 rounded-lg gap-2 transition-colors',
          getKycContainerClassName(kycStatusFlats),
          className,
        )}
      >
        <div className="flex gap-1.5 items-center flex-1">
          {isPending ? (
            <Loading className="size-4 animate-loading-spin text-base-text-quaternary" />
          ) : (
            <Attention
              className={cn('size-4', {
                'text-base-text-orange': !isError,
                'text-base-text-red': isError,
              })}
            />
          )}

          <span className="text-caption-sm-strong-m xl-msq:text-caption-sm-strong-d">
            {kycLabel}
          </span>
        </div>

        <button
          onClick={onClick}
          disabled={isPending}
          className={cn(
            'msq-btn msq-btn-sm min-w-[7.5rem] mr-1 xl-msq:mr-2 xl-msq:min-w-[7.25rem]',
            {
              'msq-btn-primary': !isPending,
              'msq-btn-tertiary': isPending,
            },
          )}
        >
          {isError ? 'Retry' : 'Verify'}
        </button>
      </div>
      <KycRequiredModal
        isDisabledSumSub={isError}
        {...kycRequiredModal.props}
        onSuccess={closeProfileModal}
      />
    </>
  );
};

export default ProfileMenuDialogKyc;
